import React from "react"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import PhotoGrid from "../components/elements/photo-grid"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useAsmetPhotos } from "../hooks/use-asmet-photos"

const PhotosPage = () => {
  const asmetHome = useAsmetHome()
  const allPhotos = useAsmetPhotos()

  const crumbs = [
    { path: `/media`, label: "Media" },
    { path: `/fotos`, label: "Fotos" },
  ]

  let flattenFotos = []
  allPhotos.forEach(el => {
    flattenFotos = flattenFotos.concat(Object.values(el))
  })

  return (
    <Layout meta={{ og_titulo: "Fotos" }}>
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">Galería de Fotos</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="asm-media-main container my-5">
        <PhotoGrid allPhotos={flattenFotos} />
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario}/>
    </Layout>
  )
}

export default PhotosPage
